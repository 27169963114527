import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJs } from "chart.js/auto";
import { useParams } from 'react-router-dom';
import { useQueries, useQuery } from 'react-query';
import { getChartData, getPointChartData } from '../api/api';
import loadingLottie from "../assets/loading_Lotte.json";
import { Center } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import moment from 'moment';
const Chart = () => {

    const params = useParams();
    const [graphData, setGraphData] = useState(null);
    const [pointData, setPointData] = useState(null);
    // const { isLoading } = useQuery(['chart_data', params.token_index], () => getChartData(params.token_index, params.type),
    //     {
    //         enabled: !!params.token_index && !!params.point_index,
    //         onSuccess(data) {
    //             const parsingData = data.results?.duplicateDates;

    //             let date = [];
    //             let chartValue = [];

    //             Object.entries(parsingData).forEach(([key, value]) => {
    //                 date.push(moment(key, 'YYYY-MM-DD').format('DD MMM'));
    //                 // date.push(key);
    //                 chartValue.push(Number.isInteger(value.usdAvgPrice) ? value.usdAvgPrice : value.usdAvgPrice.toFixed(2))
    //             })

    //             chartValue.sort((a, b) => {
    //                 return b.origin_date - a.origin_date;
    //             })

    //             setGraphData([date, chartValue]);
    //         }
    //     }
    // )

    const { isLoading } = useQueries([
        {
            queryKey: ['chart_data', params.token_index],
            queryFn: () => getChartData(params.token_index, params.type),
            enabled: !!params.token_index,
            onSuccess(data) {
                const parsingData = data.results?.duplicateDates;
                let date = [];
                let chartValue = [];

                Object.entries(parsingData).forEach(([key, value]) => {
                    date.push(moment(key, 'YYYY-MM-DD').format('DD MMM'));
                    // date.push(key);
                    chartValue.push(Number.isInteger(value.usdAvgPrice) ? value.usdAvgPrice : value.usdAvgPrice.toFixed(2))
                })

                chartValue.sort((a, b) => {
                    return b.origin_date - a.origin_date;
                })

                setGraphData([date, chartValue]);
            }
        },
        {
            queryKey: ['point_data', params.point_index],
            queryFn: () => getPointChartData(params.point_index),
            enabled: !!params.point_index,
            onSuccess(data) {
                const parsingData = data.results?.duplicateDates;
                let date = [];
                let pointValue = [];

                Object.entries(parsingData).forEach(([key, value]) => {
                    date.push(moment(key, 'YYYY-MM-DD').format('DD MMM'));
                    // date.push(moment(key, 'YYYY-MM-DD').format('DD MMM'));
                    // date.push(key);
                    pointValue.push(Number.isInteger(value.usdAvgPrice) ? String(value.usdAvgPrice) : value.usdAvgPrice.toFixed(2))
                })

                pointValue.sort((a, b) => {
                    return b.origin_date - a.origin_date;
                })

                console.log(pointValue);

                setPointData([date, pointValue]);
            }
        }
    ])

    const loading = isLoading || !graphData || !pointData

    useEffect(() => {
        console.log(params)
    }, [])

    if (loading) {
        return (
            <Center height={'100%'} width={'100%'}>
                <Lottie animationData={loadingLottie} />
            </Center>
        )
    }

    // const body = {
    //     tokens_index: coinData.index,
    //     period: '1d',
    // };
    // try {
    //     const data = await axios.post(`${SERVER_URL}/endpoints/tse_price_info`, body);
    //     const pointData = await axios.post(`${SERVER_URL}/endpoints/trade_price_info_point`, {
    //         points_index: coinData?.index,
    //     });
    //     if (coinData.hasOwnProperty('is_token')) {
    //         if (data) {
    //             return data;
    //         }
    //     }
    //     if (coinData.hasOwnProperty('is_point')) {
    //         return pointData;
    //     }
    // } catch (error) {
    //     console.log('🚀 ~ file: MarketDetail.js:121 ~ getTokenfilterData ~ error:', error);
    // } finally {
    //     setTokenDataLoading(false);
    // }

    return (
        <div style={{ width: "100%", height: '200px' }}>
            <Line
                datasetIdKey='id'
                data={
                    {
                        labels: graphData[0].reverse(),
                        datasets: [
                            {
                                id: 1,
                                label: 'Token',
                                data: graphData[1].reverse(),
                                fill: true,
                                backgroundColor: "rgba(75,192,192,0.5)",
                                borderColor: "rgba(75,192,192,1)"
                            },
                            {
                                id: 2,
                                label: "Point",
                                fill: true,
                                backgroundColor: "rgba(255, 135, 197, 0.8)",
                                borderColor: "rgba(238, 100, 171, 0.8)",
                                data: pointData[1].reverse(),
                            }
                        ],
                    }
                }
                options={{
                    pointBorderWidth: 0,
                    responsive: true,
                    tension: .2,
                }}
            />
        </div>
    )
}

export default Chart