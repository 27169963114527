import { Button, Center, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import Header from '../components/Header';

const DrawnPage = () => {

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (window.ReactNativeWebView) {
            postMessage();
            window.addEventListener('message', (event) => {
                if (!user) {
                    const parseData = JSON.parse(event.data);

                    setUser(parseData);
                }
            })
        }

    }, [])

    const postMessage = (type, data) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ type, data }));
        } else {
            console.log('Hello!')
        }
    };

    return (
        <Center flexDirection={'column'} width={'100%'} minHeight={'100vh'} height={'100vh'} background={"linear-gradient(to bottom, #212121 0%, #090909 100%)"}>

            <Flex width={'100%'} height={'70%'} padding={5} flexDirection={'column'}>
                <Flex flex={1} flexDirection={'column'}>
                    <Center flexDirection={'column'}>
                        <Image src={require('../assets/main_logo.png')} width={220} />
                        <Image src={require('../assets/eml_banner.png')} />
                    </Center>
                    <Center flexDirection={'column'}>
                        {/* <Text fontFamily={'Noto Sans KR'} fontSize={10} color={'#fff'} marginBottom={5} fontWeight={'bold'}>
                            {user?.email}
                        </Text> */}
                        <Text
                            color={'#fff'}
                            fontSize={23}
                            fontWeight={'bold'}
                        >
                            {user?.username}
                        </Text>
                    </Center>
                    <Center>
                        <Text color={'#fff'}>did you participate in the event?</Text>
                    </Center>
                </Flex>
                <Center width={'100%'}>
                    <Button
                        colorScheme='whatsapp'
                        width={'80%'}
                        height={'50px'}
                        fontFamily={'Noto Sans KR'}
                        onClick={() => { postMessage('claim', user?.email) }}>
                        Claim
                    </Button>
                </Center>
            </Flex>
        </Center>
    )
}

export default DrawnPage