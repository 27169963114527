import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

export const uploadPost = async (body) => {
    const { data } = await axios.post(`/endpoints/event_post_register`, body);

    return data;
}

export const postDetail = async (post_index) => {
    const { data } = await axios.post(`/endpoints/event_post_detail`, {
        post_index
    })

    return data;
}

export const uploadImage = async (file) => {
    const formData = new FormData();

    formData.append('files', file);

    const { data } = await axios.post(`/files`, formData, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        },
    });

    console.log(data, '데따');

    return data;
}

export const getChartData = async (index, type) => {
    // if (type === 'token') {
    const { data } = await axios.post(`/endpoints/trade_price_info`, {
        tokens_index: index,
        period: '1d',
    })

    console.log(data, '데따')

    return data;
    // }

    // if (type === 'point') {
    //     const { data } = await axios.post(`/endpoints/trade_price_info_point`, {
    //         points_index: index,
    //     })

    //     return data;
    // }

    // return []
}

export const getPointChartData = async (index) => {
    const { data } = await axios.post(`/endpoints/trade_price_info_point`, {
        points_index: index,
    })

    return data;
}